import React from "react";

import { Policy } from "../components/Policy";
import BaseLayout from "../layout/BaseLayout";

const Index: React.FC = () => {
  return (
    <BaseLayout
      header="Polityka prywatności"
      headerSeo="Polityka prywatności"
      description=""
      isAds={false}
    >
      <Policy />
    </BaseLayout>
  );
};

export default Index;
